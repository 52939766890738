import React, {useState, useLocation} from "react"
import "../css/main.css"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Form from "./Form"
import Navbar from "./Navbar"
import Careers from "./Careers"
import Cards from "./Cards"
import Qualifications from "./Qualifications"
import Experience from "./Experience"
import Coring from "./Coring"
import Cutting from "./Cutting"
// import Accordion2 from "./Accordion2"
// import Accordion from "./Accordion"
import Scanning from "./Scanning"
import Carousal from "./Carousal"
import { motion, AnimatePresence } from "framer-motion"

// const duration = 0.5
// const variants = {
//   initial: {
//     opacity: 0,
//     // x: 100
//     x: 400

//   },
//   animate: {
//     opacity: 1,
//     y: 0,
//     x: 0,
//     transition: {
//       duration: duration,
//       delay: duration,
//       when: "beforeChildren",
//     },
//   },
//   exit: {
//     opacity: 0,
//     // y: 200,
//     y: -1400,

//     transition: { duration: duration },
//   },
// }


const Layout = ({ children }) => {

  const [isOpen, setIsOpen] = React.useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
     <Navbar toggleSidebar={toggleSidebar}/>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
      {children}
      <Footer/>
    </>
  )
}




export default Layout


