import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Services from "../components/Form"
// import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Scanning from "../components/Scanning"
import Coring from "../components/Coring"
import Cutting from "../components/Cutting"
import Top1 from "../assets/services-image-1_d.jpg";
import Top2 from "../assets/services-image-1_m.jpg";
import styled from "styled-components";
import SEO from "../components/SEO"





// ...GatsbyImageSharpFluid
const ServicesPage = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "services-image-1_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)
   
  return (
    
      <Layout>
        <SEO title="Services |" description="this is the services page"/>
        <ContImg>
           <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
        </ContImg>
        <Scanning/>
        <Coring/>
        <Cutting/>
      </Layout>
 
  )
}

const ContImg = styled.div`
width: 100%;
  height: 600px;
  // background-image: url(${Top1});
  // background-size: 100% 100%;

  @media screen and (max-width: 1110px) {
  height: 400px;
}

  @media screen and (max-width: 700px) {
  height: 350px;
  // background-image: url(${Top2});
}
`

export default ServicesPage